import AOS from 'aos';
import 'aos/dist/aos.css'; 

AOS.init();

const collapsibles = document.querySelectorAll(".collapsible");
collapsibles.forEach((item) =>
  item.addEventListener("click", function () {
    this.classList.toggle("collapsible--expanded");
  })
);